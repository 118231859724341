import { takeEvery, call, put } from "redux-saga/effects"

import {
  GET_PROMOS,
  GET_PROMO,
  CREATE_PROMO,
  UPDATE_PROMO,
  DELETE_PROMO,
} from "./actionTypes"
import {
  apiError,
  apiSuccess,
  onGetPromos,
  onCreatePromo,
  onDeletePromo,
  onGetPromo,
  onUpdatePromo,
} from "./actions"

import { db } from "../../config/firebaseConfig"

import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  onSnapshot,
  query,
  where,
  addDoc,
  orderBy,
} from "firebase/firestore"
import { v4 } from "uuid"

const collectionName = "promos"
const colRef = collection(db, collectionName)

// Create Promo
function* createPromo({ payload: { data, history } }) {
  try {
    const docRef = doc(colRef)
    const promo = {
      ...data,
      id: docRef.id,
    }
    yield call(setDoc, docRef, promo)
    yield put(onCreatePromo(promo))
    yield put(apiSuccess("Promo Created Successfully"))
    history.push("/promo-codes")
  } catch (err) {
    console.log("ERROR", err.message)
    yield put(apiError(err.message))
  }
}

// Update Promo
function* updatePromo({ payload: { data, updatePromoId, history } }) {
  try {
    const docRef = doc(db, collectionName, updatePromoId)
    const promo = {
      ...data,
    }
    yield call(updateDoc, docRef, promo)
    yield put(onUpdatePromo(promo))
    history.push("/promo-codes")
  } catch (error) {
    yield put(apiError(error.message))
    console.log(error.message)
  }
}

//Get All Promos
function* getAllPromos() {
  try {
    const q = query(colRef)
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc?.data())
    console.log("promos in saga", data)
    yield put(onGetPromos(data))
  } catch (error) {
    console.log("error", error.message)
    yield put(apiError(error.message))
  }
}

// Get Promo
function* getPromo({ payload: { id } }) {
  try {
    const docRef = doc(db, collectionName, id)
    const docData = yield call(getDoc, docRef)
    const promoDetails = docData.data()
    yield put(onGetPromo(promoDetails))
  } catch (err) {
    console.log("error", err)
    yield put(apiError(err.message))
  }
}

// Delete Service

function* deletePromo({ payload }) {
  try {
    const docRef = doc(db, collectionName, payload)

    yield call(deleteDoc, docRef, payload)
    yield put(onDeletePromo(payload))
    yield put(apiSuccess("Deleted Successfully"))
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* mainSaga() {
  yield takeEvery(GET_PROMOS, getAllPromos)
  yield takeEvery(GET_PROMO, getPromo)
  yield takeEvery(CREATE_PROMO, createPromo)
  yield takeEvery(UPDATE_PROMO, updatePromo)
  yield takeEvery(DELETE_PROMO, deletePromo)
}

export default mainSaga
