import { takeEvery, call, put } from "redux-saga/effects"

import { GET_DOCUMENTS, GET_DOCUMENT, DELETE_DOCUMENT } from "./actionTypes"
import {
  apiError,
  apiSuccess,
  onGetDocument,
  onDeleteDocument,
  onGetDocuments,
} from "./actions"

import { db } from "../../config/firebaseConfig"

import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  onSnapshot,
  query,
  where,
  addDoc,
  orderBy,
} from "firebase/firestore"
import { v4 } from "uuid"

//Get All DOCUMENTS
function* getAllDocuments({ payload: { collectionName } }) {
  console.log("collectionName", collectionName)
  const colRef = collection(db, collectionName)
  try {
    const q = query(colRef)
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc?.data())
    console.log("doc in saga", data)
    yield put(onGetDocuments(data))
  } catch (error) {
    console.log("error", error.message)
    yield put(apiError(error.message))
  }
}

// Get DOCUMENT
function* getDocument({ payload: { id, collectionName } }) {
  const colRef = collection(db, collectionName, orderBy("sortingDate", "desc"))
  try {
    const docRef = doc(db, collectionName, id)
    const docData = yield call(getDoc, docRef)
    const promoDetails = docData.data()
    yield put(onGetDocument(promoDetails))
  } catch (err) {
    console.log("error", err)
    yield put(apiError(err.message))
  }
}

// Delete DOCUMENR

function* deleteDocument({ payload: { id, collectionName } }) {
  try {
    const docRef = doc(db, collectionName, id)
    yield call(deleteDoc, docRef)
    yield put(onDeleteDocument(id))
    yield put(apiSuccess("Deleted Successfully"))
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* mainSaga() {
  yield takeEvery(GET_DOCUMENTS, getAllDocuments)
  yield takeEvery(GET_DOCUMENT, getDocument)
  yield takeEvery(DELETE_DOCUMENT, deleteDocument)
}

export default mainSaga
