import {
  GET_PROMO,
  ON_GET_PROMO,
  GET_PROMOS,
  ON_GET_PROMOS,
  CREATE_PROMO,
  ON_CREATE_PROMO,
  UPDATE_PROMO,
  ON_UPDATE_PROMO,
  DELETE_PROMO,
  ON_DELETE_PROMO,
  API_ERROR,
  API_SUCCESS,
} from "./actionTypes"

export const createPromo = data => {
  return {
    type: CREATE_PROMO,
    payload: data,
  }
}

export const onCreatePromo = data => {
  return {
    type: ON_CREATE_PROMO,
    payload: data,
  }
}

export const updatePromo = data => {
  return {
    type: UPDATE_PROMO,
    payload: data,
  }
}

export const onUpdatePromo = data => {
  return {
    type: ON_UPDATE_PROMO,
    payload: data,
  }
}

export const getPromos = () => {
  return {
    type: GET_PROMOS,
    payload: {},
  }
}

export const onGetPromos = data => {
  return {
    type: ON_GET_PROMOS,
    payload: data,
  }
}

export const getPromo = data => ({
  type: GET_PROMO,
  payload: data,
})

export const onGetPromo = data => ({
  type: ON_GET_PROMO,
  payload: data,
})

export const deletePromo = data => {
  return {
    type: DELETE_PROMO,
    payload: data,
  }
}

export const onDeletePromo = data => {
  return {
    type: ON_DELETE_PROMO,
    payload: data,
  }
}

export const apiSuccess = msg => {
  return {
    type: API_SUCCESS,
    payload: msg,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
