import React, { useEffect, useState, useRef } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { Link } from "react-router-dom"
// loader
import Loader from "components/Common/Loader"
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
//redux
import {
  getDocuments,
  deleteDocument,
  apiError,
  apiSuccess,
} from "../../store/SuportAndNews/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
// delet modal
import DeleteModal from "common/DeleteModal"

const NewsLetter = props => {
  const dispatch = useDispatch()
  // delete user
  const [deleteModal, setDeleteModal] = useState(false)
  const [id, setId] = useState("")
  const { loading, btnLoading, error, data } = useSelector(state => ({
    data: state.SupportAndNews.data,
    loading: state.SupportAndNews.loading,
    btnLoading: state.SupportAndNews.btnLoading,
    error: state.SupportAndNews.error,
  }))

  //pagination customization
  const pageOptions = {
    sizePerPage: 20,
    totalSize: data.length, // replace later with size(orders),
    custom: true,
  }
  const { SearchBar } = Search

  // table columns
  const EcommerceOrderColumns = toggleModal => [
    {
      dataField: "totalNumber",
      isDummyField: true,
      text: "#",
      formatter: (cellContent, row, rowIndex) => (
        <Link
          to="#"
          className="
        fw-bold"
          style={{ color: "black" }}
        >
          {rowIndex + 1}
        </Link>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "joinDate",
      text: "Join date",
      sort: true,
    },
    {
      dataField: "joinTime",
      text: "Join time",
      sort: true,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row) => (
        <Link
          to="#"
          className="text-danger"
          onClick={() => onClickDelete(row?.id)}
        >
          <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
          <UncontrolledTooltip placement="top" target="deletetooltip">
            Delete
          </UncontrolledTooltip>
        </Link>
      ),
    },
  ]
  // tables columns end

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  // table helpers
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "sortingDate",
      order: "desc",
    },
  ]

  // handle delete user
  const onClickDelete = id => {
    setId(id)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(deleteDocument({ id, collectionName: "newsLetters" }))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  // useEffects
  useEffect(() => {
    dispatch(getDocuments({ collectionName: "newsLetters" }))
  }, [])

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      {!loading ? (
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={EcommerceOrderColumns()}
                  data={data}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={data}
                      columns={EcommerceOrderColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  ref={node}
                                />
                              </div>
                              {!data.length > 0 && (
                                <h5 className="mt-2 text-muted text-center">
                                  No data Found!
                                </h5>
                              )}
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                              <PaginationListStandalone {...paginationProps} />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  )
}

export default NewsLetter
