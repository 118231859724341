import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

import Breadcrumb from "./Breadcrumb/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Calendar
import calendar from "./calendar/reducer"
import Services from "./services/reducer"
import chat from "./chat/reducer"

// orders
import orders from "./orders/reducer"
// users
import users from "./users/reducer"
// promos
import Promos from "./promos/reducer"
// suport and news
import SupportAndNews from "./SuportAndNews/reducer"
import usersIds from "./usersIds/reducer"
const rootReducer = combineReducers({
  // public
  Layout,
  //Breadcrumb items
  Breadcrumb,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  Services,
  chat,
  users,
  orders,
  Promos,
  SupportAndNews,
  usersIds,
})

export default rootReducer
