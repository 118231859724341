import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  // AUTH_GET_SESSION_FAIL,
  // AUTH_GET_SESSION_REQUEST,
  // AUTH_GET_SESSION_SUCCESS,
} from "./actionTypes"

// export function sessionGet() {
//   return { type: AUTH_GET_SESSION_REQUEST }
// }

// export function sessionGetSuccess(session) {
//   return { payload: session, type: AUTH_GET_SESSION_SUCCESS }
// }

// export function sessionGetFail(error) {
//   return { error, type: AUTH_GET_SESSION_FAIL }
// }

export const loginUser = data => {
  return {
    type: LOGIN_USER,
    payload: data,
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
