import { takeEvery, call, put } from "redux-saga/effects"
import { GET_ORDERS, UPDATE_ORDERS, GET_ORDERS_COUNT } from "./actionTypes"
import {
  apiError,
  onGetOrders,
  onUpdateOrders,
  getOrdersCountSuccess,
} from "./actions"
import {
  collection,
  getDocs,
  query,
  orderBy,
  doc,
  updateDoc,
  where,
} from "firebase/firestore"
import { db } from "../../config/firebaseConfig"
const collectionName = "orders"
const colRef = collection(db, collectionName)

function* getAllOrders() {
  try {
    const q = query(colRef, orderBy("sortingDate", "desc"))
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc?.data())

    yield put(onGetOrders(data))
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* updateOrders({ payload: { order } }) {
  try {
    const docRef = doc(colRef, order.id)
    yield call(updateDoc, docRef, order)
    yield put(onUpdateOrders(order))
  } catch (error) {
    console.log(error.message)
    yield put(apiError(error.message))
  }
}

function* getOrdersCount({ payload }) {
  try {
    let ordersCount = {
      totalOrders: 0,
      completedOrders: 0,
      pendingOrders: 0,
    }
    let payments = {
      totalOrdersPayment: 0,
      completedOrdersPayment: 0,
      pendingOrdersPayment: 0,
    }
    const qr = yield call(getDocs, colRef)
    const qrData = qr.docs.map(doc => doc.data())
    ordersCount.totalOrders = qr.size
    for (let i = 0; i < qrData.length; i++) {
      let orderPrice = qrData[i].orderPrice || 0
      if (typeof orderPrice == "string") {
        orderPrice = parseFloat(orderPrice)
      }
      if (qrData[i].status === "completed") {
        ordersCount.completedOrders++
        payments.completedOrdersPayment =
          payments.completedOrdersPayment + orderPrice
      }
      if (qrData[i].status === "pending") {
        ordersCount.pendingOrders++
        payments.pendingOrdersPayment =
          payments.pendingOrdersPayment + orderPrice
      }
      payments.totalOrdersPayment = payments.totalOrdersPayment + orderPrice
    }
    yield put(getOrdersCountSuccess({ ordersPayments: payments, ordersCount }))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* mainSaga() {
  yield takeEvery(GET_ORDERS, getAllOrders)
  yield takeEvery(UPDATE_ORDERS, updateOrders)
  yield takeEvery(GET_ORDERS_COUNT, getOrdersCount)
}

export default mainSaga
