import React from "react"
import {
  Container,
  FormFeedback,
  CardBody,
  Card,
  Col,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"

import { Formik } from "formik"
import { useHistory } from "react-router-dom"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { createPromo } from "../../store/promos/actions"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  percentage: Yup.number().required("Percentage is required"),
  code: Yup.string().required("Code is required"),
  validDate: Yup.string().required("Valid date is required"),
  totalLimit: Yup.number().required("Limit is required"),
})
// { data, ...props }
const CreatePromoCodes = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { btnLoading, error } = useSelector(state => ({
    btnLoading: state.Promos.btnLoading,
    error: state.Promos.error,
  }))

  return (
    <Formik
      initialValues={{
        name: "",
        percentage: "",
        validDate: "",
        totalLimit: 1,
        code: "",
      }}
      validationSchema={validationSchema}
      onSubmit={values => {
        var newData = {
          ...values,
          totalLimit: parseInt(values.totalLimit),
          timesUsed: 0,
          validDate: new Date(values?.validDate).toDateString(),
        }
        dispatch(createPromo({ data: newData, history }))
      }}
    >
      {({
        errors,
        setFieldValue,
        values,
        handleBlur,
        handleChange,
        touched,
        handleSubmit,
      }) => {
        return (
          <div>
            <Container fluid={true}>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Create Promo</h4>
                      <div className="wizard clearfix">
                        <div className="content clearfix mt-4">
                          <Row className="d-flex justify-content-between">
                            <Col md={5}>
                              <div className="mb-3">
                                <Label className="form-label">Name</Label>
                                <Input
                                  name="name"
                                  type="text"
                                  placeholder="Enter Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name || ""}
                                  invalid={
                                    touched.name && errors.name ? true : false
                                  }
                                />
                                {touched.name && errors.name ? (
                                  <FormFeedback type="invalid">
                                    {errors.name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={5}>
                              <div className="mb-3">
                                <Label className="form-label">Percentage</Label>
                                <Input
                                  name="percentage"
                                  type="number"
                                  placeholder="Enter percentage"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  step="any"
                                  value={values.percentage || ""}
                                  invalid={
                                    touched.percentage && errors.percentage
                                      ? true
                                      : false
                                  }
                                />
                                {touched.percentage && errors.percentage ? (
                                  <FormFeedback type="invalid">
                                    {errors.percentage}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>{" "}
                          <Row className="d-flex justify-content-between">
                            <Col md={5}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Number of times allowed
                                </Label>
                                <Input
                                  name="totalLimit"
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  min={1}
                                  value={values.totalLimit || ""}
                                  invalid={
                                    touched.totalLimit && errors.totalLimit
                                      ? true
                                      : false
                                  }
                                />
                                {touched.totalLimit && errors.totalLimit ? (
                                  <FormFeedback type="invalid">
                                    {errors.totalLimit}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>{" "}
                          <Row className="mt-4 d-flex justify-content-between">
                            <Col md={5}>
                              <div className="mb-3">
                                <Label className="form-label">Code</Label>
                                <Input
                                  name="code"
                                  type="text"
                                  placeholder="Enter Code"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.code || ""}
                                  invalid={
                                    touched.code && errors.code ? true : false
                                  }
                                />
                                {touched.code && errors.code ? (
                                  <FormFeedback type="invalid">
                                    {errors.code}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={5}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Expiry date
                                </Label>
                                <Input
                                  name="validDate"
                                  type="date"
                                  onChange={e =>
                                    setFieldValue("validDate", e.target.value)
                                  }
                                  onBlur={handleBlur}
                                  value={values.validDate || ""}
                                  invalid={
                                    touched.validDate && errors.validDate
                                      ? true
                                      : false
                                  }
                                />
                                {touched.validDate && errors.validDate ? (
                                  <FormFeedback type="invalid">
                                    {errors.validDate}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <div className="mt-5 text-center">
                            {!btnLoading ? (
                              <button
                                className="btn btn-primary btn-block btn-md w-50"
                                type="submit"
                                onClick={handleSubmit}
                              >
                                Add Promo
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary btn-block btn-md w-50"
                                type="submit"
                                disabled
                              >
                                Adding...
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        )
      }}
    </Formik>
  )
}

export default CreatePromoCodes
