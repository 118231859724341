import React, { useEffect, useState } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Container,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table"
import Loader from "components/Common/Loader"

import { addUsersIds, getUsersIds } from "../../store/usersIds/actions"

import * as XLSX from "xlsx"
import { useDispatch, useSelector } from "react-redux"
function UsersIds() {
  const dispatch = useDispatch()
  const { loading, data } = useSelector(state => ({
    loading: state.usersIds.loading,
    data: state.usersIds.data || [],
  }))
  const [excelData, setExcelData] = useState([])
  const handleChange = e => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = () => {
      const workbook = XLSX.read(reader.result, { type: "binary" })
      const worksheet = workbook.Sheets[workbook.SheetNames[0]]
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      setExcelData(data)
    }
  }

  const handleSubmit = () => {
    let data = [...excelData]
    data.shift()
    data = data.map(item => item[0])
    dispatch(addUsersIds(data))
  }

  useEffect(() => {
    dispatch(getUsersIds())
  }, [])

  return (
    <Container>
      {loading && <Loader />}
      <Col md={4}>
        <div className="mx-4 my-2">
          <p className="fs-5">Excel</p>
          <input type="file" onChange={handleChange} accept="text/xls" />
          {excelData.length > 0 ? (
            <div className="text-primary"> {excelData.length - 1} records</div>
          ) : null}
          <div className="fs-6 my-3 text-secondary">
            Upload yout Excel file here{" "}
          </div>

          <button
            className="btn btn-secondary btn-sm"
            style={{ alignSelf: "flex-end" }}
            disabled={excelData.length > 0 ? false : true}
            onClick={handleSubmit}
          >
            Upload
          </button>
        </div>
      </Col>
      <Row>
        <Col md={12}>
          <div className="table-rep-plugin">
            <div className="table-responsive">
              <Table className="project-list-table table table-striped table-borderless">
                <Thead>
                  <tr>
                    <th data-priority="1">#</th>
                    <th data-priority="1">User Ids</th>
                  </tr>
                </Thead>
                <Tbody>
                  {data.length > 0
                    ? data.map((item, index) => (
                        <Tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item}</td>
                        </Tr>
                      ))
                    : null}
                </Tbody>
              </Table>

              {!loading && !data.length > 0 ? (
                <div className="d-flex justify-content-center">
                  <p className="fs-6">Empty</p>
                </div>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default UsersIds
