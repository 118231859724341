import { takeEvery, call, put, takeLatest } from "redux-saga/effects"

import { ADD_USERS_IDS, GET_USERS_IDS } from "./actionTypes"
import { onAddUsersIds, onGetUsersIds, apiError, apiSuccess } from "./actions"

import { storage, db } from "../../config/firebaseConfig"

import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  onSnapshot,
  query,
  where,
  addDoc,
  orderBy,
} from "firebase/firestore"

const asDocRef = doc(db, "appSettings", "currentUserCount")
function* addUsersIds({ payload }) {
  try {
    const ids = payload
    const asDocRef = doc(db, "appSettings", "currentUserCount")
    const asGetDoc = yield call(getDoc, asDocRef)
    const asData = asGetDoc.data()
    const { cardUIDs = [] } = asData || {}
    const setIDs = [...new Set([...cardUIDs, ...ids])]
    yield call(updateDoc, asDocRef, {
      cardUIDs: setIDs,
    })
    yield put(onAddUsersIds(setIDs))
  } catch (err) {
    yield apiError(err.message)
  }
}

function* getUsersIds({ payload }) {
  try {
    const asDocRef = doc(db, "appSettings", "currentUserCount")
    const asGetDoc = yield call(getDoc, asDocRef)
    const asData = asGetDoc.data()
    const { cardUIDs = [] } = asData || {}
    yield put(onGetUsersIds(cardUIDs))
  } catch (err) {
    yield apiError(err.message)
  }
}

function* root() {
  yield takeEvery(GET_USERS_IDS, getUsersIds)
  yield takeLatest(ADD_USERS_IDS, addUsersIds)
}

export default root
