import {
  API_SUCCESS,
  API_ERROR,
  GET_SERVICES,
  ON_GET_SERVICES,
  CREATE_SERVICE,
  ON_CREATE_SERVICE,
  DELETE_SERVICE,
  ON_DELETE_SERVICE,
  GET_SERVICE,
  ON_GET_SERVICE,
  UPDATE_SERVICE,
  ON_UPDATE_SERVICE,
} from "./actionTypes"

const initialState = {
  loading: false,
  btnLoading: false,
  data: [],
  serviceData: {},
  error: "",
  success: "",
}

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SERVICE: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_CREATE_SERVICE: {
      state = {
        ...state,
        btnLoading: false,
        data: [...state.data, action.payload],
      }
      break
    }

    case UPDATE_SERVICE: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_UPDATE_SERVICE: {
      state = {
        ...state,
        btnLoading: false,
        data: [...state.data, action.payload],
      }
      break
    }

    case GET_SERVICES: {
      state = { ...state, loading: true }
      break
    }
    case ON_GET_SERVICES: {
      state = { ...state, loading: false, data: action.payload }
      break
    }

    case GET_SERVICE: {
      state = { ...state, loading: true, btnLoading: true, serviceData: {} }
      break
    }
    case ON_GET_SERVICE:
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        serviceData: action.payload,
      }
      break

    case DELETE_SERVICE: {
      state = { ...state, loading: true }
      break
    }
    case ON_DELETE_SERVICE: {
      state = {
        ...state,
        loading: false,
        data: state.data.filter(d => d.id != action.payload),
      }
      break
    }

    case API_SUCCESS: {
      state = { ...state, btnLoading: false, success: action.payload }
      break
    }
    case API_ERROR: {
      state = {
        ...state,
        error: action.paylaod,
        btnLoading: false,
        loading: false,
      }
      break
    }
  }
  return state
}

export default serviceReducer
