import {
  GET_USERS_IDS,
  ON_ADD_USERS_IDS,
  ADD_USERS_IDS,
  ON_GET_USERS_IDS,
  API_ERROR,
  API_SUCCESS,
} from "./actionTypes"

const initialState = {
  data: [],
  loading: false,
  error: "",
  success: "",
}

const state = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USERS_IDS:
    case GET_USERS_IDS:
      state = { ...state, loading: true }
      break
    case ON_ADD_USERS_IDS:
    case ON_GET_USERS_IDS:
      state = { ...state, loading: false, data: action.payload }
      break
    case API_ERROR:
      state = { ...state, loading: false, error: action.payload }
      break
    case API_SUCCESS:
      state = { ...state, loading: false, success: action.payload }
      break
    default:
      state = { ...state }
  }
  return state
}

export default state
