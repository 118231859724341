import { takeEvery, call, put } from "redux-saga/effects"
import { GET_USERS, DELETE_USER, GET_USERS_COUNT } from "./actionTypes"
import {
  apiError,
  onGetUsers,
  onDeleteUsers,
  getUsersCountSuccess,
} from "./actions"
import {
  collection,
  getDocs,
  query,
  doc,
  deleteDoc,
  orderBy,
  where,
} from "firebase/firestore"
import { db } from "../../config/firebaseConfig"
const collectionName = "users"
const colRef = collection(db, collectionName)

function* getAllUsers() {
  try {
    const q = query(colRef, orderBy("createdAt", "desc"))
    const response = yield call(getDocs, q)
    const sortedData = response.docs.map(doc => doc?.data())
    const unsortedQuery = query(colRef)
    const unsortedResponse = yield call(getDocs, unsortedQuery)
    const unsortedData = unsortedResponse.docs.map(doc => doc.data())
    const filterUnsortedData = unsortedData.filter(
      doc => !sortedData.some(sortDoc => sortDoc.userId == doc.userId)
    )
    yield put(onGetUsers([...sortedData, ...filterUnsortedData]))
  } catch (error) {
    console.log("err", error.message)
    yield put(apiError(error.message))
  }
}

function* getUsersCount() {
  try {
    const users = yield call(getDocs, colRef)
    yield put(getUsersCountSuccess(users.size))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* deleteUser({ payload }) {
  console.log("payload", payload)
  try {
    const docRef = doc(db, collectionName, payload)
    yield call(deleteDoc, docRef)
    yield put(onDeleteUsers(payload))
  } catch (err) {
    console.log(err.message)
    yield put(apiError(err.message))
  }
}

function* mainSaga() {
  yield takeEvery(GET_USERS, getAllUsers)
  yield takeEvery(DELETE_USER, deleteUser)
  yield takeEvery(GET_USERS_COUNT, getUsersCount)
}

export default mainSaga
