export const GET_SERVICES = "GET_SERVICES"
export const ON_GET_SERVICES = "ON_GET_SERVICES"

export const GET_SERVICE = "GET_SERVICE"
export const ON_GET_SERVICE = "ON_GET_SERVICE"

export const CREATE_SERVICE = "CREATE_SERVICE"
export const ON_CREATE_SERVICE = "ON_CREATE_SERVICE"

export const UPDATE_SERVICE = "UPDATE_SERVICE"
export const ON_UPDATE_SERVICE = "ON_UPDATE_SERVICE"

export const DELETE_SERVICE = "DELETE_SERVICE"
export const ON_DELETE_SERVICE = "ON_DELETE_SERVICE"

export const API_SUCCESS = "API_SUCCESS"
export const API_ERROR = "API_ERROR"
