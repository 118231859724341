import React, { useState, useEffect } from "react"
import { Col, Row, Spinner } from "reactstrap"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import SweetAlert from "react-bootstrap-sweetalert"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { deleteService, getServices } from "../../store/services/actions"

const Services = () => {
  const [isEffects, setisEffects] = useState(false)
  const [selectedImage, setSelectedImage] = useState("")
  const [deleteServiceAlert, setDeleteServiceAlert] = useState(false)
  const [deleteServiceId, setDeleteServiceId] = useState("")

  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, btnLoading, error, data } = useSelector(state => ({
    data: state.Services.data,
    loading: state.Services.loading,
    btnLoading: state.Services.btnLoading,
    error: state.Services.error,
  }))

  const handleDeleteService = () => {
    dispatch(deleteService(deleteServiceId))
  }

  useEffect(() => {
    dispatch(getServices())
  }, [])

  return (
    <React.Fragment>
      {deleteServiceAlert && (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setDeleteServiceAlert(false)
            handleDeleteService()
          }}
          onCancel={() => setDeleteServiceAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      )}
      <div style={{ marginBottom: "15px" }}>
        <button
          type="button"
          className="btn btn-primary "
          onClick={() => {
            history.push("/product")
          }}
        >
          Add product
        </button>
      </div>
      <div>
        {isEffects ? (
          <Lightbox
            mainSrc={selectedImage}
            enableZoom={false}
            onCloseRequest={() => {
              setisEffects(!isEffects)
            }}
          />
        ) : null}
        <Row>
          <Col md={12}>
            <div className="table-rep-plugin">
              <div className="table-responsive">
                <Table className="project-list-table table table-striped">
                  <Thead>
                    <tr>
                      <th scope="col" style={{ width: "100px" }}>
                        #
                      </th>
                      <th scope="col">Name</th>
                      <th scope="col">Title</th>
                      <th scope="col">Price</th>
                      <th scope="col">Action</th>
                    </tr>
                  </Thead>
                  <Tbody>
                    {data.length > 0 &&
                      data.map((item, index) => (
                        <Tr key={index}>
                          <Td>
                            <img
                              src={item?.images?.[0]}
                              alt=""
                              className="avatar-sm"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelectedImage(item?.images?.[0])
                                setisEffects(true)
                              }}
                            />
                          </Td>
                          <Td>
                            <h5 className="text-truncate font-size-14">
                              <span className="text-dark">{item.name}</span>
                            </h5>
                          </Td>
                          <Td>
                            <h5 className="text-truncate font-size-14">
                              <span className="text-dark">{item.title}</span>
                            </h5>
                          </Td>
                          <Td>
                            <h5 className="text-truncate font-size-14">
                              <span className="text-dark">
                                {item.price} USD
                              </span>
                            </h5>
                          </Td>
                          <Td>
                            <div style={{ display: "flex", gap: "20px" }}>
                              <div
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  history.push(`/product/${item.id}`, {
                                    service: item,
                                  })
                                }
                              >
                                <i
                                  style={{ color: "#e0e0e0" }}
                                  className="mdi mdi-pencil font-size-20 text-success me-1"
                                />
                              </div>

                              <div
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setDeleteServiceId(item.id)
                                  setDeleteServiceAlert(true)
                                }}
                              >
                                <i
                                  // style={{ color: "#e0e0e0" }}
                                  className="mdi mdi-delete font-size-20 text-danger me-1"
                                />
                              </div>
                            </div>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" color="primary" />
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Services
