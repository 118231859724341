import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { signInWithEmailAndPassword, signOut } from "firebase/auth"
import * as QB from "quickblox/quickblox"
import { db, auth } from "../../../config/firebaseConfig"
import { getDoc, doc } from "firebase/firestore"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

function* loginUser({ payload: { user, history } }) {
  try {
    yield call(signInWithEmailAndPassword, auth, user.email, user.password)
    const res = yield call(
      signInWithEmailAndPassword,
      auth,
      user.email,
      user.password
    )
    const id = res.user.uid
    // console.log(id)
    const docRef = doc(db, "admin", id)
    const adminRes = yield call(getDoc, docRef)
    const adminData = adminRes.data()
    if (!adminData) {
      console.log("user not found")
      yield call(signOut, auth)
      localStorage.removeItem("authUser")

      yield put(logoutUserSuccess(""))
      history.push("/login")
      throw new Error("User with this email does not exists!")
    }
    // console.log("user found")
    const dt = yield call(getDoc, docRef)
    localStorage.setItem("authUser", JSON.stringify(adminData))

    yield put(loginSuccess("login succesfully"))
    history.push("/dashboard")
  } catch (error) {
    let { message } = error
    if (
      message == "Firebase: Error (auth/user-not-found)." ||
      message == "Firebase: Error (auth/wrong-password)."
    ) {
      message = "Invalid Email or Password"
    }
    yield put(apiError(message))
  }
}

function* logoutUserSaga({ payload: { history } }) {
  try {
    yield call(signOut, auth)
    localStorage.removeItem("authUser")
    yield put(logoutUserSuccess(""))
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUserSaga)
}

export default authSaga
