import {
  GET_USERS_IDS,
  ON_ADD_USERS_IDS,
  ADD_USERS_IDS,
  ON_GET_USERS_IDS,
  API_ERROR,
  API_SUCCESS,
} from "./actionTypes"

export const getUsersIds = data => ({
  type: GET_USERS_IDS,
  payload: data,
})
export const onGetUsersIds = data => ({
  type: ON_GET_USERS_IDS,
  payload: data,
})
export const addUsersIds = data => ({
  type: ADD_USERS_IDS,
  payload: data,
})

export const onAddUsersIds = data => ({
  type: ON_ADD_USERS_IDS,
  payload: data,
})

export const apiError = data => ({
  type: API_ERROR,
  payload: data,
})

export const apiSuccess = data => ({
  type: API_SUCCESS,
  payload: data,
})
