export const GET_ORDERS = "GET_ORDERS"
export const ON_GET_ORDERS = "ON_GET_ORDERS"

export const UPDATE_ORDERS = "UPDATE_ORDERS"
export const ON_UPDATE_ORDERS = "ON_UPDATE_ORDERS"

export const API_SUCCESS = "API_SUCCESS"
export const API_ERROR = "API_ERROR"

export const GET_ORDERS_COUNT = "GET_ORDERS_COUNT"
export const ON_GET_ORDERS_COUNT = "ON_GET_ORDERS_COUNT"
