import {
  GET_SERVICES,
  ON_GET_SERVICES,
  CREATE_SERVICE,
  ON_CREATE_SERVICE,
  UPDATE_SERVICE,
  ON_UPDATE_SERVICE,
  DELETE_SERVICE,
  ON_DELETE_SERVICE,
  API_ERROR,
  API_SUCCESS,
  GET_SERVICE,
  ON_GET_SERVICE,
} from "./actionTypes"

export const createService = data => {
  return {
    type: CREATE_SERVICE,
    payload: data,
  }
}

export const onCreateService = data => {
  return {
    type: ON_CREATE_SERVICE,
    payload: data,
  }
}

export const updateService = data => {
  return {
    type: UPDATE_SERVICE,
    payload: data,
  }
}

export const onUpdateService = data => {
  return {
    type: ON_UPDATE_SERVICE,
    payload: data,
  }
}

export const getServices = () => {
  return {
    type: GET_SERVICES,
    payload: {},
  }
}

export const onGetServices = data => {
  return {
    type: ON_GET_SERVICES,
    payload: data,
  }
}

export const getService = data => ({
  type: GET_SERVICE,
  payload: data,
})

export const onGetService = data => ({
  type: ON_GET_SERVICE,
  payload: data,
})

export const deleteService = data => {
  return {
    type: DELETE_SERVICE,
    payload: data,
  }
}

export const onDeleteService = data => {
  return {
    type: ON_DELETE_SERVICE,
    payload: data,
  }
}

export const apiSuccess = msg => {
  return {
    type: API_SUCCESS,
    payload: msg,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
