import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Input,
  Label,
  Container,
} from "reactstrap"
import { Formik } from "formik"
import NoImage from "assets/images/No_image.png"

const UserDetailsModal = ({ openUserModal, openUserModalHandler, user }) => {
  return (
    <Modal
      isOpen={openUserModal}
      toggle={() => {
        openUserModalHandler()
      }}
      centered
      size="lg"
    >
      <div className="modal-content">
        <ModalHeader toggle={() => openUserModalHandler()}>
          User Details
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              orderId: "bankOrderOne",
              Title: user?.Title || "",
              address: user?.address || "",
              city: user?.city || "",
              companyName: user?.companyName || "",
              contactType: user?.contactType || "",
              country: user?.country || "",
              coverPic: user?.coverPic || null,
              email: user?.email || "",
              facebookUrl: user?.facebookUrl || "",
              firstName: user?.firstName || "",
              instagramUrl: user?.instagramUrl || "",
              linkedInUrl: user?.linkedInUrl || "",
              mobileNumber: user?.mobileNumber || "",
              officeNumber: user?.officeNumber || "",
              profilePic: user?.profilePic || null,
              secondName: user?.secondName || "",
              state: user?.state || "",
              tiktokUrl: user?.tiktokUrl || "",
              website: user?.website || "",
              zip: user?.zip || "",
              whatsappNumber: user?.whatsappNumber || "",
              twitterUrl: user?.twitterUrl || "",
              youtubeUrl: user?.youtubeUrl || "",
            }}
          >
            {({
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              touched,
              errors,
            }) => {
              return (
                <div>
                  <div className="text-center">
                    {values.profilePic ? (
                      <img
                        src={values?.profilePic}
                        alt=""
                        className="rounded-circle"
                        style={{ width: "110px", height: "110px" }}
                      />
                    ) : (
                      <img
                        src={NoImage}
                        alt=""
                        className="rounded-circle"
                        style={{ width: "110px", height: "110px" }}
                      />
                    )}
                  </div>
                  <Row className="d-flex justify-content-between">
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">Order ID</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="orderId"
                          className="form-control"
                          value={values.orderId}
                          readOnly
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between">
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">First Name</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="firstName"
                          className="form-control"
                          value={values.firstName}
                          readOnly
                        />
                      </div>
                    </Col>
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">Last Name</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="secondName"
                          className="form-control"
                          readOnly
                          value={values.secondName}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between">
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">Job Title</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="Title"
                          className="form-control"
                          readOnly
                          value={values.Title}
                        />
                      </div>
                    </Col>
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">
                          Company Name
                        </Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="companyName"
                          className="form-control"
                          readOnly
                          value={values.companyName}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between">
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">
                          Contact Type
                        </Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="contactType"
                          className="form-control"
                          readOnly
                          value={values.contactType}
                        />
                      </div>
                    </Col>
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">Email</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="email"
                          className="form-control"
                          readOnly
                          value={values.email}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between">
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">Website</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="website"
                          className="form-control"
                          readOnly
                          value={values.website}
                        />
                      </div>
                    </Col>
                  </Row>
                  <p className="text-center text-muted fw-bold fs-5 mt-3 mb-3">
                    Address
                  </p>
                  <Row className="d-flex justify-content-between">
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">Country</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="country"
                          className="form-control"
                          readOnly
                          value={values.country}
                        />
                      </div>
                    </Col>
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">State</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="state"
                          className="form-control"
                          readOnly
                          value={values.state}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between">
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">City</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="city"
                          className="form-control"
                          readOnly
                          value={values.city}
                        />
                      </div>
                    </Col>
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">Zip</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="zip"
                          className="form-control"
                          readOnly
                          value={values.zip}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between">
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">Address</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="address"
                          className="form-control"
                          readOnly
                          value={values.address}
                        />
                      </div>
                    </Col>
                  </Row>
                  <p className="text-center text-muted fw-bold fs-5 mt-3 mb-3">
                    Contacts
                  </p>
                  <Row className="d-flex justify-content-between">
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">
                          Mobile Number
                        </Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="mobileNumber"
                          className="form-control"
                          readOnly
                          value={values.mobileNumber}
                        />
                      </div>
                    </Col>
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">
                          Office Number
                        </Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="officeNumber"
                          className="form-control"
                          readOnly
                          value={values.officeNumber}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between">
                    <Col md="5">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">
                          Whatsapp Number
                        </Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="whatsappNumber"
                          className="form-control"
                          readOnly
                          value={values.whatsappNumber}
                        />
                      </div>
                    </Col>
                  </Row>
                  <p className="text-center text-muted fw-bold fs-5 mt-3 mb-3">
                    Social Urls
                  </p>
                  <Row className="d-flex justify-content-between">
                    <Col md="12">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">Facebook</Label>
                        <Input
                          type="url"
                          id="basicpill-packagedetails-input1"
                          name="facebookUrl"
                          className="form-control"
                          readOnly
                          value={values.facebookUrl}
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">Instagram</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="instagramUrl"
                          className="form-control"
                          readOnly
                          value={values.instagramUrl}
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">Titktok</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="tiktokUrl"
                          className="form-control"
                          readOnly
                          value={values.tiktokUrl}
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">linkedIn</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="linkedInUrl"
                          className="form-control"
                          readOnly
                          value={values.linkedInUrl}
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">Twitter</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="twitterUrl"
                          className="form-control"
                          readOnly
                          value={values.twitterUrl}
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">Youtube</Label>
                        <Input
                          type="text"
                          id="basicpill-packagedetails-input1"
                          name="youtubeUrl"
                          className="form-control"
                          readOnly
                          value={values.youtubeUrl}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              )
            }}
          </Formik>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={() => openUserModalHandler()}
          >
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default UserDetailsModal
