import {
  API_SUCCESS,
  API_ERROR,
  GET_DOCUMENT,
  ON_GET_DOCUMENT,
  GET_DOCUMENTS,
  ON_GET_DOCUMENTS,
  DELETE_DOCUMENT,
  ON_DELETE_DOCUMENT,
} from "./actionTypes"

const initialState = {
  loading: false,
  btnLoading: false,
  data: [],
  error: "",
  success: "",
}

const SupportAndNews = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCUMENTS: {
      state = { ...state, loading: true, btnLoading: true }
      break
    }
    case ON_GET_DOCUMENTS: {
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        data: action.payload,
      }
      break
    }

    case GET_DOCUMENT: {
      state = { ...state, loading: true, btnLoading: true, promoData: {} }
      break
    }
    case ON_GET_DOCUMENT:
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        promoData: action.payload,
      }
      break

    case DELETE_DOCUMENT: {
      state = { ...state, loading: true }
      break
    }
    case ON_DELETE_DOCUMENT: {
      state = {
        ...state,
        loading: false,
        data: state.data.filter(p => p.id != action.payload),
      }
      break
    }

    case API_SUCCESS: {
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        success: action.payload,
      }
      break
    }
    case API_ERROR: {
      state = {
        ...state,
        error: action.paylaod,
        btnLoading: false,
        loading: false,
      }
      break
    }
  }
  return state
}

export default SupportAndNews
