import { takeEvery, call, put } from "redux-saga/effects"

import {
  GET_SERVICES,
  GET_SERVICE,
  CREATE_SERVICE,
  UPDATE_SERVICE,
  DELETE_SERVICE,
} from "./actionTypes"
import {
  apiError,
  apiSuccess,
  onGetServices,
  onCreateService,
  onDeleteService,
  onGetService,
  onUpdateService,
} from "./actions"

import { storage, db } from "../../config/firebaseConfig"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"

import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  onSnapshot,
  query,
  where,
  addDoc,
  orderBy,
} from "firebase/firestore"
import { uploadFiles } from "helpers/uploadFiles"
import { v4 } from "uuid"

const collectionName = "products"
const colRef = collection(db, collectionName)

// Create Service
function* createService({ payload: { data, history } }) {
  try {
    // const docRef = doc(colRef)
    const uploadAttachments = yield uploadFiles(data?.images, "productImages")
    const docRef = doc(colRef)
    const service = {
      // ...data,
      name: data.name,
      price: data.price,
      title: data.title,
      description: data.description,
      id: docRef.id,
    }
    if (uploadAttachments) {
      service.images = uploadAttachments
    }
    yield call(setDoc, docRef, service)
    yield put(onCreateService(service))
    yield put(apiSuccess("Product Created Successfully"))
    history.push("/products")
  } catch (err) {
    console.log("ERROR", err.message)
    yield put(apiError(err.message))
  }
}

// Update Service

function* updateService({ payload: { data, updateServiceId, history } }) {
  try {
    const docRef = doc(db, collectionName, updateServiceId)
    // upload attachments
    const uploadAttachments = yield Promise.all(
      data?.images?.map(attch => {
        if (typeof attch == "object") {
          return new Promise((resolve, reject) => {
            uploadBytes(ref(storage, `productImages/${v4()}`), attch)
              .then(res => {
                getDownloadURL(res.ref)
                  .then(res => {
                    resolve(res)
                  })
                  .catch(err => {
                    reject(err)
                  })
              })
              .catch(err => {
                reject(err)
              })
          })
        } else {
          return attch
        }
      })
    )

    const service = {
      name: data.name,
      price: data.price,
      title: data.title,
      description: data.description,
    }

    if (uploadAttachments) {
      service.images = uploadAttachments
    }

    yield call(updateDoc, docRef, service)

    yield put(onUpdateService(data))
    history.push("/products")
  } catch (error) {
    yield put(apiError(error.message))
    console.log(error.message)
  }
}

//Get All Services
function* getAllServices() {
  try {
    const q = query(colRef, orderBy("name"))
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc?.data())
    yield put(onGetServices(data))
  } catch (error) {
    console.log("error", err.message)
    yield put(apiError(err.message))
  }
}

// Get Service

function* getService({ payload: { id } }) {
  try {
    const docRef = doc(db, collectionName, id)
    const docData = yield call(getDoc, docRef)
    const serviceDetails = docData.data()
    yield put(onGetService(serviceDetails))
  } catch (err) {
    console.log("error", err)
    yield put(apiError(err.message))
  }
}

// Delete Service

function* deleteService({ payload }) {
  try {
    const docRef = doc(db, collectionName, payload)

    yield call(deleteDoc, docRef, payload)
    yield put(onDeleteService(payload))
    yield put(apiSuccess("Deleted Successfully"))
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* mainSaga() {
  yield takeEvery(GET_SERVICES, getAllServices)
  yield takeEvery(GET_SERVICE, getService)
  yield takeEvery(CREATE_SERVICE, createService)
  yield takeEvery(UPDATE_SERVICE, updateService)
  yield takeEvery(DELETE_SERVICE, deleteService)
}

export default mainSaga
