import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"

//Import Action to copy breadcrumb items from local state to redux state
import {
  setBreadcrumbItems,
  getOrdersCount,
  getUsersCount,
} from "../../store/actions"

import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Row } from "reactstrap"
import avatar1 from "../../assets/images/main.png"
import Loader from "components/Common/Loader"

const breadcrumbItems = [
  { title: "Justtap", link: "#" },
  { title: "Dashboard", link: "#" },
]

const Dashboard = props => {
  const dispatch = useDispatch()
  const { ordersCount, ordersPayments, loading, usersCount } = useSelector(
    state => {
      return {
        ordersCount: state.orders.ordersCount,
        ordersPayments: state.orders.ordersPayments,
        loading: state.orders.loading,
        usersCount: state.users.usersCount,
      }
    }
  )

  useEffect(() => {
    dispatch(setBreadcrumbItems("Dashboard", breadcrumbItems))
    dispatch(getOrdersCount())
    dispatch(getUsersCount())
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Just-tap admin</title>
      </MetaTags>
      {loading && <Loader />}
      <Card>
        <CardBody>
          <Row>
            <Col lg="4">
              <div className="d-flex">
                <div className="me-3">
                  <img
                    src={avatar1}
                    alt=""
                    className="avatar-sm img-thumbnail rounded-circle"
                  />
                </div>
                <div className="flex-grow-1 align-self-center">
                  <div className="text-muted">
                    <p className="mb-2">Welcome to JustTap Dashboard</p>
                    <h5 className="mb-1">Admin Panel</h5>
                    <p className="mb-0">Statistics</p>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg="4" className="align-self-center">
              <div className="text-lg-center mt-4 mt-lg-0">
                <Row>
                  <Col xs="4">
                    <div>
                      <p className="text-muted text-truncate mb-2">
                        Total Users
                      </p>
                      <h5 className="mb-0">{usersCount}</h5>
                    </div>
                  </Col>
                  <Col xs="4">
                    <div>
                      <p className="text-muted text-truncate mb-2">
                        Total Orders
                      </p>
                      <h5 className="mb-0">{ordersCount?.totalOrders}</h5>
                    </div>
                  </Col>
                  <Col xs="4">
                    <div>
                      <p className="text-muted text-truncate mb-2">
                        Total Payments
                      </p>
                      <h5 className="mb-0">
                        {"ZK "}
                        {ordersPayments?.totalOrdersPayment?.toFixed(2)}
                      </h5>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Row>
        <Col lg={4}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Total Orders</p>
                  <h5 className="mb-0">{ordersCount?.totalOrders}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col lg={4}>
          <Card className="blog-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Completed Orders</p>
                  <h5 className="mb-0">{ordersCount?.completedOrders}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="blog-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Pending Orders</p>
                  <h5 className="mb-0">{ordersCount?.pendingOrders}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Total Orders Payment</p>
                  <h5 className="mb-0">
                    {"ZK "}
                    {ordersPayments?.totalOrdersPayment?.toFixed(2)}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col lg={4}>
          <Card className="blog-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Completed Orders Paymennt</p>
                  <h5 className="mb-0">
                    {"ZK "}
                    {ordersPayments?.completedOrdersPayment?.toFixed(2)}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="blog-stats-wid">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Pending Orders Payment</p>
                  <h5 className="mb-0">
                    {"ZK "}
                    {ordersPayments?.pendingOrdersPayment?.toFixed(2)}
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Dashboard
