import {
  API_SUCCESS,
  API_ERROR,
  GET_PROMOS,
  ON_GET_PROMOS,
  CREATE_PROMO,
  ON_CREATE_PROMO,
  DELETE_PROMO,
  ON_DELETE_PROMO,
  GET_PROMO,
  ON_GET_PROMO,
  UPDATE_PROMO,
  ON_UPDATE_PROMO,
} from "./actionTypes"

const initialState = {
  loading: false,
  btnLoading: false,
  data: [],
  promoData: {},
  error: "",
  success: "",
}

const promoReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROMO: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_CREATE_PROMO: {
      state = {
        ...state,
        btnLoading: false,
        data: [...state.data, action.payload],
      }
      break
    }

    case UPDATE_PROMO: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_UPDATE_PROMO: {
      state = {
        ...state,
        btnLoading: false,
        data: [...state.data, action.payload],
      }
      break
    }

    case GET_PROMOS: {
      state = { ...state, loading: true, btnLoading: true }
      break
    }
    case ON_GET_PROMOS: {
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        data: action.payload,
      }
      break
    }

    case GET_PROMO: {
      state = { ...state, loading: true, btnLoading: true, promoData: {} }
      break
    }
    case ON_GET_PROMO:
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        promoData: action.payload,
      }
      break

    case DELETE_PROMO: {
      state = { ...state, loading: true }
      break
    }
    case ON_DELETE_PROMO: {
      state = {
        ...state,
        loading: false,
        data: state.data.filter(p => p.id != action.payload),
      }
      break
    }

    case API_SUCCESS: {
      state = { ...state, btnLoading: false, success: action.payload }
      break
    }
    case API_ERROR: {
      state = {
        ...state,
        error: action.paylaod,
        btnLoading: false,
        loading: false,
      }
      break
    }
  }
  return state
}

export default promoReducer
