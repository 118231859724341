import {
  API_SUCCESS,
  API_ERROR,
  GET_USERS,
  ON_GET_USERS,
  DELETE_USER,
  ON_DELETE_USER,
  GET_USERS_COUNT,
  GET_USERS_COUNT_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: false,
  btnLoading: false,
  data: [],
  usersCount: 0,
  error: "",
  success: "",
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_COUNT:
    case GET_USERS: {
      state = { ...state, loading: true }
      break
    }
    case ON_GET_USERS: {
      state = { ...state, loading: false, data: action.payload }
      break
    }

    case GET_USERS_COUNT_SUCCESS: {
      state = { ...state, loading: false, usersCount: action.payload }
      break
    }

    case DELETE_USER: {
      state = { ...state, loading: true }
      break
    }
    case ON_DELETE_USER: {
      state = {
        ...state,
        loading: false,
        data: state.data.filter(user => user.uidNumber != action.payload),
      }
      break
    }

    case API_SUCCESS: {
      state = { ...state, btnLoading: false, success: action.payload }
      break
    }
    case API_ERROR: {
      state = {
        ...state,
        error: action.paylaod,
        btnLoading: false,
        loading: false,
      }
      break
    }
  }
  return state
}

export default usersReducer
