import React, { useEffect } from "react"
import {
  Container,
  FormFeedback,
  CardBody,
  Card,
  Col,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import DropAttachments from "components/Common/DropAttachments"

import { Formik } from "formik"
import { Link, useHistory, useParams, useLocation } from "react-router-dom"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { updateService } from "../../store/services/actions"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  price: Yup.string().required("Price is required."),
  images: Yup.array()
    .min(1, "Min 1 attachment required")
    .max(5, "Max 5 attachments required"),
})

const UpdateService = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const location = useLocation()

  const { serviceData, loading, btnLoading, error } = useSelector(state => ({
    data: state.Services.data,
    serviceData: state.Services.serviceData,
    loading: state.Services.loading,
    btnLoading: state.Services.btnLoading,
    error: state.Services.error,
  }))

  const { service } = location.state

  const updateServiceId = params.id

  return (
    <div>
      {loading && <div>Loading...</div>}
      <Formik
        initialValues={{
          name: service.name,
          images: service.images || [],
          title: service.title || "",
          description: service.description || "",
          price: service.price || "",
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          dispatch(updateService({ data: values, updateServiceId, history }))
        }}
      >
        {({
          errors,
          setFieldValue,
          values,
          handleBlur,
          handleChange,
          touched,
          handleSubmit,
        }) => {
          function removeImage(i) {
            setFieldValue(
              "images",
              values.images.filter((f, index) => index !== i)
            )
          }
          function handleSelectedFiles(files) {
            setFieldValue("images", [...values.images, ...files])
          }

          return (
            <div>
              <Container fluid={true}>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <h4 className="card-title mb-4">Update Service</h4>

                        <div className="wizard clearfix">
                          <div className="content clearfix mt-4">
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">Name</Label>
                                  <Input
                                    name="name"
                                    type="text"
                                    placeholder="Enter Name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    invalid={
                                      touched.name && errors.name ? true : false
                                    }
                                  />
                                  {touched.name && errors.name ? (
                                    <FormFeedback type="invalid">
                                      {errors.name}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">Price</Label>
                                  <Input
                                    name="price"
                                    type="number"
                                    placeholder="Enter Price"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.price || ""}
                                    invalid={
                                      touched.price && errors.price
                                        ? true
                                        : false
                                    }
                                  />
                                  {touched.price && errors.price ? (
                                    <FormFeedback type="invalid">
                                      {errors.price}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">Title</Label>
                                  <Input
                                    name="title"
                                    type="text"
                                    placeholder="Enter Title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title || ""}
                                    invalid={
                                      touched.title && errors.title
                                        ? true
                                        : false
                                    }
                                  />
                                  {touched.title && errors.title ? (
                                    <FormFeedback type="invalid">
                                      {errors.title}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Description
                                  </Label>
                                  <Input
                                    name="description"
                                    type="textarea"
                                    rows="8"
                                    col="8"
                                    placeholder="Enter Price"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description || ""}
                                    invalid={
                                      touched.description && errors.description
                                        ? true
                                        : false
                                    }
                                  />
                                  {touched.description && errors.description ? (
                                    <FormFeedback type="invalid">
                                      {errors.description}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Services Images
                                  </Label>
                                  <DropAttachments
                                    handleDropFiles={handleSelectedFiles}
                                  />
                                  <div
                                    className="dropzone-previews mt-3"
                                    id="file-previews"
                                  >
                                    {values.images.map((f, index) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-4 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                          key={index + "-file"}
                                        >
                                          <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                              <Row className="align-items-center">
                                                <Col className="col-auto">
                                                  <img
                                                    data-dz-thumbnail=""
                                                    height="80"
                                                    width="80"
                                                    alt="attachments"
                                                    src={f?.preview || f}
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </Col>
                                                <Col
                                                  style={{
                                                    wordBreak: "break-word",
                                                  }}
                                                >
                                                  <Link
                                                    to="#"
                                                    className="text-muted font-weight-bold"
                                                  >
                                                    {f.name}
                                                  </Link>
                                                  <p className="mb-0">
                                                    <strong>Attachments</strong>
                                                  </p>
                                                </Col>
                                              </Row>
                                              <i
                                                className="mdi mdi-delete text-danger h3 mx-4"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  removeImage(index)
                                                }
                                              ></i>
                                            </div>
                                          </div>
                                        </Card>
                                      )
                                    })}
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <div className="mt-4 text-center">
                              {!btnLoading ? (
                                <button
                                  className="btn btn-primary btn-block btn-md w-50"
                                  type="submit"
                                  onClick={handleSubmit}
                                >
                                  Update Service
                                </button>
                              ) : (
                                <Spinner animation="border" color="success" />
                              )}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          )
        }}
      </Formik>
    </div>
  )
}

export default UpdateService
