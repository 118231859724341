import React, { useState, useEffect } from "react"
import { Col, Row, Spinner } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { deletePromo, getPromos } from "../../store/promos/actions"

const PromoCodes = () => {
  const [deletePromoAlert, setDeletePromoAlert] = useState(false)
  const [deletePromoId, setDeletePromoId] = useState("")

  const dispatch = useDispatch()
  const history = useHistory()
  const { loading, btnLoading, error, data } = useSelector(state => ({
    data: state.Promos.data,
    loading: state.Promos.loading,
    btnLoading: state.Promos.btnLoading,
    error: state.Promos.error,
  }))

  const handleDeletePromo = () => {
    dispatch(deletePromo(deletePromoId))
  }

  useEffect(() => {
    dispatch(getPromos())
  }, [])

  console.log("promos", data)

  return (
    <React.Fragment>
      {deletePromoAlert && (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setDeletePromoAlert(false)
            handleDeletePromo()
          }}
          onCancel={() => setDeletePromoAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      )}
      <div style={{ marginBottom: "15px" }}>
        <button
          type="button"
          className="btn btn-primary "
          onClick={() => {
            history.push("/add-promo-code")
          }}
        >
          Add Promo
        </button>
      </div>
      <div>
        <Row>
          <Col md={12}>
            <div className="table-rep-plugin">
              <div className="table-responsive">
                <Table className="project-list-table table table-striped">
                  <Thead>
                    <tr>
                      <th scope="col" style={{ width: "100px" }}>
                        #
                      </th>
                      <th scope="col">Name</th>
                      <th scope="col">Code</th>
                      <th scope="col">Percentage</th>
                      <th scope="col">Number of times allowed</th>
                      <th scope="col">Number of times used</th>
                      <th scope="col">Expiry date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </Thead>
                  <Tbody>
                    {data.length > 0 &&
                      data.map((item, index) => (
                        <Tr key={index}>
                          <Td>
                            <h5 className="text-truncate font-size-14">
                              {index + 1}
                            </h5>
                          </Td>
                          <Td>
                            <h5 className="text-truncate font-size-14">
                              <span>{item?.name}</span>
                            </h5>
                          </Td>
                          <Td>
                            <h5 className="text-truncate font-size-14">
                              <span>{item?.code}</span>
                            </h5>
                          </Td>
                          <Td>
                            <h5 className="text-truncate font-size-14">
                              <span>{item?.percentage} %</span>
                            </h5>
                          </Td>
                          <Td>
                            <h5 className="text-truncate font-size-14">
                              <span>{item?.totalLimit} </span>
                            </h5>
                          </Td>
                          <Td>
                            <h5 className="text-truncate font-size-14">
                              <span>{item?.timesUsed} </span>
                            </h5>
                          </Td>
                          <Td>
                            <h5 className="text-truncate font-size-14">
                              <span>{item?.validDate}</span>
                            </h5>
                          </Td>
                          <Td>
                            <div style={{ display: "flex", gap: "20px" }}>
                              <div
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  history.push(
                                    `/update-promo-code/${item?.id}`,
                                    {
                                      promoData: item,
                                    }
                                  )
                                }
                              >
                                <i
                                  style={{ color: "#e0e0e0" }}
                                  className="mdi mdi-pencil font-size-20 text-success me-1"
                                />
                              </div>

                              <div
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setDeletePromoId(item.id)
                                  setDeletePromoAlert(true)
                                }}
                              >
                                <i
                                  // style={{ color: "#e0e0e0" }}
                                  className="mdi mdi-delete font-size-20 text-danger me-1"
                                />
                              </div>
                            </div>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" color="primary" />
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default PromoCodes
