import React, { useEffect, useState, useRef } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { Link } from "react-router-dom"
// loader
import Loader from "components/Common/Loader"
import { Button, Card, CardBody, Col, Row, Badge, Modal } from "reactstrap"
//redux
import {
  getOrders,
  updateOrders,
  apiError,
  apiSuccess,
} from "../../store/orders/actions"
import { useSelector, useDispatch } from "react-redux"
// order details modal
import OrderDetailsModal from "./OrderDetailsModal"

const Users = props => {
  const dispatch = useDispatch()
  const [openCommentModal, setOpenCommentModal] = useState(false)
  //
  const [selectedOrder, setSelectedOrder] = useState("")
  // get data from tickets saga
  const { orders, loading, btnLoading, success, error } = useSelector(
    state => ({
      orders: state.orders.data,
      loading: state.orders.loading,
      success: state.orders.success,
      error: state.orders.error,
      btnLoading: state.orders.btnLoading,
    })
  )

  //pagination customization
  const pageOptions = {
    sizePerPage: 20,
    totalSize: orders.length, // replace later with size(orders),
    custom: true,
  }
  const { SearchBar } = Search

  // table columns
  const EcommerceOrderColumns = toggleModal => [
    {
      dataField: "orderId",
      text: "Order ID",
      formatter: (cellContent, row) => (
        <Link
          to="#"
          className="
        fw-bold"
          style={{ color: "black" }}
        >
          {row?.orderId}
        </Link>
      ),
    },
    {
      dataField: "firstName",
      text: "Buyer",
    },
    {
      dataField: "phone",
      text: "Phone",
    },
    {
      dataField: "productName",
      text: "Product",
      formatter: (cellContent, row) => row.product?.name,
    },
    {
      dataField: "promoData",
      text: "Promo Code",
      formatter: (cellContent, row) => row?.promoData?.code,
    },
    {
      dataField: "totalAmount",
      text: "Payment",
      formatter: (cellContent, row) => row?.quantity * row.product?.price,
    },
    {
      dataField: "orderDate",
      text: "Order Date",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row?.orderDate),
    },
    {
      dataField: "orderTime",
      text: "Order Time",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => {
        return (
          <Badge
            className={`font-size-12 badge-soft-${
              row?.status == "pending" ? "warning" : "success"
            }`}
            color="#fff"
            pill
          >
            {row?.status == "pending" ? "Pending" : "Completed"}
          </Badge>
        )
      },
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "Mark Completed",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <Button
          type="button"
          color="warning"
          className="btn-sm btn-rounded"
          onClick={() => handlOrderComplete(order)}
        >
          Completed
        </Button>
      ),
    },
    {
      dataField: "orderStatus",
      isDummyField: true,
      text: "Details",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={() => setOrderHandler(order)}
        >
          View Details
        </Button>
      ),
    },
  ]
  // tables columns end

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  // table helpers
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderDate",
      order: "desc",
    },
  ]

  // tables helpers end
  //set order handler
  function setOrderHandler(order) {
    setSelectedOrder(order)
    openCommentModalHandler()
  }

  //openCommentModalHandler
  function openCommentModalHandler() {
    setOpenCommentModal(!openCommentModal)
  }

  // order complete
  const handlOrderComplete = data => {
    let order = { ...data, status: "completed" }
    dispatch(updateOrders({ order }))
  }

  // useEffects

  useEffect(() => {
    dispatch(getOrders())
  }, [])

  return (
    <React.Fragment>
      {!loading ? (
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={EcommerceOrderColumns()}
                  data={orders}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={EcommerceOrderColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  ref={node}
                                />
                              </div>
                              {!orders.length > 0 && (
                                <h5 className="mt-2 text-muted text-center">
                                  No Orders Found!
                                </h5>
                              )}
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                              <PaginationListStandalone {...paginationProps} />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
      <OrderDetailsModal
        openCommentModal={openCommentModal}
        openCommentModalHandler={openCommentModalHandler}
        order={selectedOrder}
      />
    </React.Fragment>
  )
}

export default Users
