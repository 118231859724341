export const GET_DOCUMENT = "GET_DOCUMENT"
export const ON_GET_DOCUMENT = "ON_GET_DOCUMENT"

export const GET_DOCUMENTS = "GET_DOCUMENTS"
export const ON_GET_DOCUMENTS = "ON_GET_DOCUMENTS"

export const DELETE_DOCUMENT = "DELETE_DOCUMENT"
export const ON_DELETE_DOCUMENT = "ON_DELETE_DOCUMENT"

export const API_SUCCESS = "API_SUCCESS"
export const API_ERROR = "API_ERROR"
