export const GET_USERS = "GET_USERS"
export const ON_GET_USERS = "ON_GET_USERS"

export const DELETE_USER = "DELETE_USER"
export const ON_DELETE_USER = "ON_DELETE_USER"

export const GET_USERS_COUNT = "GET_USERS_COUNT"
export const GET_USERS_COUNT_SUCCESS = "GET_USERS_COUNT_SUCCESS"

export const API_SUCCESS = "API_SUCCESS_USERS"
export const API_ERROR = "API_ERROR_USERS"
