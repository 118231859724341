import React from "react"
import {
  Container,
  FormFeedback,
  CardBody,
  Card,
  Col,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"

import { Formik } from "formik"
import { Link, useHistory, useParams, useLocation } from "react-router-dom"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { updatePromo } from "../../store/promos/actions"
import moment from "moment"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  percentage: Yup.number().required("Percentage is required"),
  code: Yup.string().required("Code is required"),
  validDate: Yup.string().required("Valid date is required"),
  totalLimit: Yup.number().required("Limit is required"),
})

const UpdatePromoCode = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const location = useLocation()

  const { loading, btnLoading, error } = useSelector(state => ({
    loading: state.Promos.loading,
    btnLoading: state.Promos.btnLoading,
    error: state.Promos.error,
  }))
  const { promoData } = location.state
  const updatePromoId = params.id

  return (
    <div>
      {loading && <div>Loading...</div>}
      <Formik
        initialValues={{
          name: promoData?.name || "",
          percentage: promoData?.percentage || "",
          validDate: moment(promoData.validDate).format("YYYY-MM-DD") || "",
          code: promoData.code || "",
          totalLimit: promoData.totalLimit || "",
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          var data = {
            ...values,
            totalLimit: parseInt(values.totalLimit),
            validDate: new Date(values?.validDate).toDateString(),
          }
          dispatch(updatePromo({ data, updatePromoId, history }))
        }}
      >
        {({
          errors,
          setFieldValue,
          values,
          handleBlur,
          handleChange,
          touched,
          handleSubmit,
        }) => {
          return (
            <div>
              <Container fluid={true}>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <h4 className="card-title mb-4">Update Promo</h4>
                        <div className="wizard clearfix">
                          <div className="content clearfix mt-4">
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">Name</Label>
                                  <Input
                                    name="name"
                                    type="text"
                                    placeholder="Enter Name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name || ""}
                                    invalid={
                                      touched.name && errors.name ? true : false
                                    }
                                  />
                                  {touched.name && errors.name ? (
                                    <FormFeedback type="invalid">
                                      {errors.name}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Percentage
                                  </Label>
                                  <Input
                                    name="percentage"
                                    type="number"
                                    step="any"
                                    placeholder="Enter percentage"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.percentage || ""}
                                    invalid={
                                      touched.percentage && errors.percentage
                                        ? true
                                        : false
                                    }
                                  />
                                  {touched.percentage && errors.percentage ? (
                                    <FormFeedback type="invalid">
                                      {errors.percentage}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>{" "}
                            <Row className="d-flex justify-content-between">
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Number of times allowed
                                  </Label>
                                  <Input
                                    name="totalLimit"
                                    type="number"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    min={1}
                                    value={values.totalLimit || ""}
                                    invalid={
                                      touched.totalLimit && errors.totalLimit
                                        ? true
                                        : false
                                    }
                                  />
                                  {touched.totalLimit && errors.totalLimit ? (
                                    <FormFeedback type="invalid">
                                      {errors.totalLimit}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>{" "}
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">Code</Label>
                                  <Input
                                    name="code"
                                    type="string"
                                    placeholder="Enter Code"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.code || ""}
                                    invalid={
                                      touched.code && errors.code ? true : false
                                    }
                                  />
                                  {touched.code && errors.code ? (
                                    <FormFeedback type="invalid">
                                      {errors.code}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Expiry date
                                  </Label>
                                  <Input
                                    name="validDate"
                                    type="date"
                                    placeholder="Enter date"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.validDate}
                                    invalid={
                                      touched.validDate && errors.validDate
                                        ? true
                                        : false
                                    }
                                  />
                                  {touched.validDate && errors.validDate ? (
                                    <FormFeedback type="invalid">
                                      {errors.validDate}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <div className="mt-4 text-center">
                              {!btnLoading ? (
                                <button
                                  className="btn btn-primary btn-block btn-md w-50"
                                  type="submit"
                                  onClick={handleSubmit}
                                >
                                  Update Promo
                                </button>
                              ) : (
                                <Spinner animation="border" color="success" />
                              )}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          )
        }}
      </Formik>
    </div>
  )
}

export default UpdatePromoCode
