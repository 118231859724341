import {
  GET_ORDERS,
  ON_GET_ORDERS,
  UPDATE_ORDERS,
  ON_UPDATE_ORDERS,
  API_ERROR,
  API_SUCCESS,
  GET_ORDERS_COUNT,
  ON_GET_ORDERS_COUNT,
} from "./actionTypes"

export const getOrders = () => {
  return {
    type: GET_ORDERS,
    payload: {},
  }
}

export const onGetOrders = data => {
  return {
    type: ON_GET_ORDERS,
    payload: data,
  }
}

export const updateOrders = data => {
  return {
    type: UPDATE_ORDERS,
    payload: data,
  }
}

export const onUpdateOrders = data => {
  return {
    type: ON_UPDATE_ORDERS,
    payload: data,
  }
}

export const apiSuccess = msg => {
  return {
    type: API_SUCCESS,
    payload: msg,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const getOrdersCount = data => {
  return {
    type: GET_ORDERS_COUNT,
    payload: data,
  }
}
export const getOrdersCountSuccess = data => {
  return {
    type: ON_GET_ORDERS_COUNT,
    payload: data,
  }
}
