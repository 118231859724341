export const GET_PROMO = "GET_PROMO"
export const ON_GET_PROMO = "ON_GET_PROMO"

export const GET_PROMOS = "GET_PROMOS"
export const ON_GET_PROMOS = "ON_GET_PROMOS"

export const CREATE_PROMO = "CREATE_PROMO"
export const ON_CREATE_PROMO = "ON_CREATE_PROMO"

export const UPDATE_PROMO = "UPDATE_PROMO"
export const ON_UPDATE_PROMO = "ON_UPDATE_PROMO"

export const DELETE_PROMO = "DELETE_PROMO"
export const ON_DELETE_PROMO = "ON_DELETE_PROMO"

export const API_SUCCESS = "API_SUCCESS"
export const API_ERROR = "API_ERROR"
