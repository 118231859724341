import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Row,
  Col,
} from "reactstrap"
import * as moment from "moment"

const OrderDetailsModal = ({
  openCommentModal,
  openCommentModalHandler,
  order,
}) => {
  // table helpers
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }
  return (
    <Modal
      isOpen={openCommentModal}
      toggle={() => {
        openCommentModalHandler()
      }}
      centered
      size="xl"
    >
      <div className="modal-content">
        <ModalHeader toggle={() => openCommentModalHandler()}>
          Order Details
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={6}>
              <p className="mb-3">
                Product id:{" "}
                <span className="text-primary">{order?.product?.id}</span>
              </p>
              <p className="mb-3">
                Buyer Name: <span>{order?.firstName}</span>
              </p>
              <p className="mb-3">
                Phone: <span>{order?.phone}</span>
              </p>
              <p className="mb-3">
                Email: <span>{order?.email}</span>
              </p>
            </Col>
            <Col className="my-3" lg={10}>
              <p className="mb-3">
                Notes: <span>{order?.description}</span>
              </p>
            </Col>
          </Row>

          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
                <tr>
                  <th scope="col">Image</th>
                  <th scope="col">Order ID</th>
                  <th scope="col">Promo code</th>
                  <th scope="col">Product name</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Price</th>
                  <th scope="col">Order date</th>
                  <th scope="col">Order time</th>
                  <th scope="col">Delivery method</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <div>
                      <img
                        src={order?.product?.images?.[0]}
                        alt=""
                        className="avatar-md"
                      />
                    </div>
                  </th>
                  <td>
                    <div className="fw-bold">{order?.orderId}</div>
                  </td>
                  <td>
                    <div className="fw-bold">{order?.promoData?.code}</div>
                  </td>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        {order?.product?.name}
                      </h5>
                    </div>
                  </td>
                  <td>{order?.quantity}</td>
                  <td>$ {order?.product?.price * order?.quantity}</td>
                  <td>{handleValidDate(order?.orderDate)}</td>
                  <td>{order?.orderTime}</td>
                  <td>{order?.deliveryMethod}</td>
                </tr>
                <tr>
                  <td colSpan="7">
                    <h6 className="m-0 text-end">Sub Total:</h6>
                  </td>
                  <td>$ {order?.product?.price * order?.quantity}</td>
                </tr>
                <tr>
                  <td colSpan="7">
                    <h6 className="m-0 text-end">Discount:</h6>
                  </td>
                  <td>
                    ${" "}
                    {order?.promoData
                      ? (
                          (order.promoData?.percentage / 100) *
                          order?.product?.price
                        ).toFixed(2)
                      : "0.0"}
                  </td>
                </tr>
                <tr className="fs-4 fw-bold">
                  <td colSpan="7">
                    <h6 className=" text-end fs-4 fw-bold">Total:</h6>
                  </td>
                  {order.promoData ? (
                    <td>
                      ${" "}
                      {order?.product?.price * order?.quantity -
                        (
                          (order.promoData?.percentage / 100) *
                          order?.product?.price
                        ).toFixed(2)}
                    </td>
                  ) : (
                    <td>$ {order?.product?.price * order?.quantity}</td>
                  )}
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={() => openCommentModalHandler()}
          >
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default OrderDetailsModal
