export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

// export const AUTH_GET_SESSION_REQUEST = "AUTH_GET_SESSION_REQUEST"
// export const AUTH_GET_SESSION_SUCCESS = "AUTH_GET_SESSION_SUCCESS"
// export const AUTH_GET_SESSION_FAIL = "AUTH_GET_SESSION_FAIL"
