import {
  GET_USERS,
  ON_GET_USERS,
  API_ERROR,
  API_SUCCESS,
  DELETE_USER,
  ON_DELETE_USER,
  GET_USERS_COUNT,
  GET_USERS_COUNT_SUCCESS,
} from "./actionTypes"

export const getUsers = () => {
  return {
    type: GET_USERS,
    payload: {},
  }
}

export const onGetUsers = data => {
  return {
    type: ON_GET_USERS,
    payload: data,
  }
}

export const deleteUser = data => {
  return {
    type: DELETE_USER,
    payload: data,
  }
}

export const onDeleteUsers = data => {
  return {
    type: ON_DELETE_USER,
    payload: data,
  }
}

export const getUsersCount = data => {
  return {
    type: GET_USERS_COUNT,
    payload: data,
  }
}

export const getUsersCountSuccess = data => {
  return {
    type: GET_USERS_COUNT_SUCCESS,
    payload: data,
  }
}

export const apiSuccess = msg => {
  return {
    type: API_SUCCESS,
    payload: msg,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
