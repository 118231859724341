import React, { useEffect } from "react"
import {
  Container,
  FormFeedback,
  CardBody,
  Card,
  Col,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import DropAttachments from "components/Common/DropAttachments"

import { Formik } from "formik"
import { Link, useHistory, useParams } from "react-router-dom"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { createService } from "../../store/services/actions"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  price: Yup.number().required("Price is required"),
  description: Yup.string().required("Description is required"),
  title: Yup.string().required("title is required"),
  images: Yup.array()
    .min(1, "Min 1 attachment required")
    .max(5, "Max 5 attachments required"),
})
// { data, ...props }
const CreateService = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const id = useParams()
  const { loading, btnLoading, error } = useSelector(state => ({
    loading: state.Services.loading,
    btnLoading: state.Services.btnLoading,
    error: state.Services.error,
  }))

  return (
    <Formik
      initialValues={{
        name: "",
        images: [],
        price: "",
        title: "",
        description: "",
      }}
      validationSchema={validationSchema}
      onSubmit={values => {
        dispatch(createService({ data: values, history }))
      }}
    >
      {({
        errors,
        setFieldValue,
        values,
        handleBlur,
        handleChange,
        touched,
        handleSubmit,
      }) => {
        function removeImage(i) {
          setFieldValue(
            "images",
            values.images.filter((f, index) => index !== i)
          )
        }
        function handleSelectedFiles(files) {
          setFieldValue("images", [...values.images, ...files])
        }

        return (
          <div>
            <Container fluid={true}>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Create Product</h4>

                      <div className="wizard clearfix">
                        <div className="content clearfix mt-4">
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Name</Label>
                                <Input
                                  name="name"
                                  type="text"
                                  placeholder="Enter Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name || ""}
                                  invalid={
                                    touched.name && errors.name ? true : false
                                  }
                                />
                                {touched.name && errors.name ? (
                                  <FormFeedback type="invalid">
                                    {errors.name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Price</Label>
                                <Input
                                  name="price"
                                  type="number"
                                  placeholder="Enter Price"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.price || ""}
                                  invalid={
                                    touched.price && errors.price ? true : false
                                  }
                                />
                                {touched.price && errors.price ? (
                                  <FormFeedback type="invalid">
                                    {errors.price}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Title</Label>
                                <Input
                                  name="title"
                                  type="text"
                                  placeholder="Enter Title"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.title || ""}
                                  invalid={
                                    touched.title && errors.title ? true : false
                                  }
                                />
                                {touched.title && errors.title ? (
                                  <FormFeedback type="invalid">
                                    {errors.title}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Description
                                </Label>
                                <Input
                                  name="description"
                                  type="textarea"
                                  rows="8"
                                  col="8"
                                  placeholder="Enter Price"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.description || ""}
                                  invalid={
                                    touched.description && errors.description
                                      ? true
                                      : false
                                  }
                                />
                                {touched.description && errors.description ? (
                                  <FormFeedback type="invalid">
                                    {errors.description}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Product Images
                                </Label>
                                <DropAttachments
                                  handleDropFiles={handleSelectedFiles}
                                />
                                <div
                                  className="dropzone-previews mt-3"
                                  id="file-previews"
                                >
                                  {values?.images.map((f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete m-3"
                                        key={i + "-file"}
                                      >
                                        <div className="p-2">
                                          <div className="d-flex justify-content-between align-items-center">
                                            <Row className="align-items-center">
                                              <Col className="col-auto">
                                                <img
                                                  data-dz-thumbnail=""
                                                  height="80"
                                                  className="avatar-sm rounded bg-light"
                                                  alt={f.name}
                                                  src={f.preview}
                                                />
                                              </Col>
                                              <Col>
                                                <Link
                                                  to="#"
                                                  className="text-muted font-weight-bold"
                                                >
                                                  {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                  <strong>
                                                    {f.formattedSize}
                                                  </strong>
                                                </p>
                                              </Col>
                                            </Row>
                                            <i
                                              className="mdi mdi-delete text-danger h3 mx-4"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => removeImage(i)}
                                            ></i>
                                          </div>
                                        </div>
                                      </Card>
                                    )
                                  })}
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <div className="mt-4 text-center">
                            {!btnLoading ? (
                              <button
                                className="btn btn-primary btn-block btn-md w-50"
                                type="submit"
                                onClick={handleSubmit}
                              >
                                Create Product
                              </button>
                            ) : (
                              <Spinner animation="border" color="success" />
                            )}
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        )
      }}
    </Formik>
  )
}

export default CreateService
