import { all, fork } from "redux-saga/effects"
//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import Services from "./services/saga"
import chatSaga from "./chat/saga"
// users saga
import UsersSaga from "./users/saga"
// orders saga
import OrdersSaga from "./orders/saga"
// promo saga
import PromoSaga from "./promos/saga"

import SupportAndNewsSaga from "./SuportAndNews/saga"
import usersIdsSaga from "./usersIds/saga"

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    fork(calendarSaga),
    fork(Services),
    fork(chatSaga),

    fork(UsersSaga),
    fork(OrdersSaga),
    fork(PromoSaga),
    fork(SupportAndNewsSaga),
    fork(usersIdsSaga),
  ])
}
