import {
  GET_DOCUMENT,
  ON_GET_DOCUMENT,
  DELETE_DOCUMENT,
  ON_DELETE_DOCUMENT,
  GET_DOCUMENTS,
  ON_GET_DOCUMENTS,
  API_ERROR,
  API_SUCCESS,
} from "./actionTypes"

export const getDocument = () => {
  return {
    type: GET_DOCUMENT,
    payload: {},
  }
}

export const onGetDocument = data => {
  return {
    type: ON_GET_DOCUMENT,
    payload: data,
  }
}

export const getDocuments = data => ({
  type: GET_DOCUMENTS,
  payload: data,
})

export const onGetDocuments = data => ({
  type: ON_GET_DOCUMENTS,
  payload: data,
})

export const deleteDocument = data => {
  return {
    type: DELETE_DOCUMENT,
    payload: data,
  }
}

export const onDeleteDocument = data => {
  return {
    type: ON_DELETE_DOCUMENT,
    payload: data,
  }
}

export const apiSuccess = msg => {
  return {
    type: API_SUCCESS,
    payload: msg,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
