import React from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from "reactstrap"

const SupportDetailsModal = ({ openModal, openModalHandler, data }) => {
  return (
    <Modal
      isOpen={openModal}
      toggle={() => {
        openModalHandler()
      }}
      centered
      size="xl"
    >
      <div className="modal-content">
        <ModalHeader toggle={() => openModalHandler()}>Details</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={6}>
              <p className="mb-3">
                Name: <span className="text-primary">{data?.name}</span>
              </p>
              <p className="mb-3">
                Email: <span>{data?.email}</span>
              </p>
              <p className="mb-3">
                Inquiry: <span>{data?.inquiry}</span>
              </p>
              <p className="mb-3">
                subject: <span>{data?.subject}</span>
              </p>
            </Col>
            <Col lg={6}>
              <p className="mb-3">
                Date: <span className="text-primary">{data?.date}</span>
              </p>
              <p className="mb-3">
                Time: <span>{data?.time}</span>
              </p>
            </Col>
            <Col className="my-3" lg={10}>
              <p className="mb-3">
                Message: <span>{data?.message}</span>
              </p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={() => openModalHandler()}
          >
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default SupportDetailsModal
