import {
  API_SUCCESS,
  API_ERROR,
  UPDATE_ORDERS,
  ON_UPDATE_ORDERS,
  GET_ORDERS,
  ON_GET_ORDERS,
  ON_GET_ORDERS_COUNT,
  GET_ORDERS_COUNT,
} from "./actionTypes"

const initialState = {
  loading: false,
  btnLoading: false,
  data: [],
  ordersCount: {},
  ordersPayments: {},
  error: "",
  success: "",
}

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS_COUNT:
    case GET_ORDERS: {
      state = { ...state, loading: true }
      break
    }
    case ON_GET_ORDERS: {
      state = { ...state, loading: false, data: action.payload }
      break
    }

    case ON_GET_ORDERS_COUNT: {
      state = {
        ...state,
        loading: false,
        ordersCount: action.payload.ordersCount,
        ordersPayments: action.payload.ordersPayments,
      }
      break
    }

    case UPDATE_ORDERS: {
      state = { ...state, loading: true, btnLoading: true }
      break
    }

    case ON_UPDATE_ORDERS: {
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        data: state.data.map(order =>
          order.id == action.payload.id
            ? { ...order, ...action.payload }
            : order
        ),
      }
      break
    }
    case API_SUCCESS: {
      state = { ...state, btnLoading: false, success: action.payload }
      break
    }
    case API_ERROR: {
      state = {
        ...state,
        error: action.paylaod,
        btnLoading: false,
        loading: false,
      }
      break
    }
  }
  return state
}

export default ordersReducer
