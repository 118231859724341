import React from "react"
import { Redirect } from "react-router-dom"

// just Tap routes
import Users from "../pages/users/index"
import Orders from "../pages/Orders/index"
import Dashboard from "../pages/Dashboard/index"
import Services from "pages/Services/Services"
import CreateService from "pages/Services/CreateService"
import UpdateService from "pages/Services/UpdateService"
// promos routes
import CreatePromoCode from "pages/PromoCodes/CreatePromoCodes"
import UpdatePromoCode from "pages/PromoCodes/UpdatePromoCodes"
import PromoCodes from "pages/PromoCodes/PromoCodes"
// news letter and support
import NewsLetter from "pages/NewsLetter/NewsLetter"
import Support from "pages/Support/Support"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

import Chats from "pages/Chats/Chats"
// import Chat from "pages/Chats/Chat"
import UsersIds from "pages/UsersIds"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // // // //profile
  // { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/products", component: Services },
  { path: "/product", component: CreateService },
  { path: "/product/:id", component: UpdateService },
  { path: "/users", component: Users },
  { path: "/orders", component: Orders },
  // promo codes
  { path: "/promo-codes", component: PromoCodes },
  { path: "/add-promo-code", component: CreatePromoCode },
  { path: "/update-promo-code/:id", component: UpdatePromoCode },

  { path: "/news-letter", component: NewsLetter },
  { path: "/support", component: Support },

  { path: "/chats", component: Chats },
  { path: "/users-ids", component: UsersIds },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
]

export { userRoutes, authRoutes }
