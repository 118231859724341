import React, { useEffect, useState, useRef } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { Link } from "react-router-dom"
// loader
import Loader from "components/Common/Loader"
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
//redux
import {
  getUsers,
  deleteUser,
  apiError,
  apiSuccess,
} from "../../store/users/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
// order details modal
import UserDetailsModal from "./UsersDetailsModal"
// delet modal
import DeleteModal from "common/DeleteModal"

const Users = props => {
  const dispatch = useDispatch()
  const [openUserModal, setOpenUserModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState("")
  // delete user
  const [deleteModal, setDeleteModal] = useState(false)
  const [uidNumber, setUidNumber] = useState("")
  // get data from tickets saga
  const { users, loading, btnLoading, success, error } = useSelector(state => ({
    users: state.users.data,
    loading: state.users.loading,
    success: state.users.success,
    error: state.users.error,
    btnLoading: state.users.btnLoading,
  }))

  //pagination customization
  const pageOptions = {
    sizePerPage: 20,
    totalSize: users.length, // replace later with size(orders),
    custom: true,
  }
  const { SearchBar } = Search

  // table columns
  const EcommerceOrderColumns = toggleModal => [
    {
      dataField: "totalNumber",
      isDummyField: true,
      text: "#",
      formatter: (cellContent, row, rowIndex) => (
        <Link
          to="#"
          className="
        fw-bold"
          style={{ color: "black" }}
        >
          {rowIndex + 1}
        </Link>
      ),
    },
    {
      dataField: "orderId",
      text: "Order ID",
      formatter: (cellContent, row) => (
        <Link
          to="#"
          className="
        fw-bold"
          style={{ color: "black" }}
        >
          {row?.orderId}
        </Link>
      ),
    },
    {
      dataField: "uidNumber",
      text: "User ID",
      formatter: (cellContent, row) => (
        <Link
          to="#"
          className="
        fw-bold"
          style={{ color: "black" }}
        >
          {row?.uidNumber}
        </Link>
      ),
    },
    {
      dataField: "firstName",
      text: "Name",
      sort: true,
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "mobileNumber",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "companyName",
      text: "Company",
      sort: true,
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <Button
          type="button"
          color="secondary"
          className="btn-sm btn-rounded"
          onClick={() => setUserHandler(user)}
        >
          View Details
        </Button>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row) => (
        <Link
          to="#"
          className="text-danger"
          onClick={() => onClickDelete(row?.uidNumber)}
        >
          <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
          <UncontrolledTooltip placement="top" target="deletetooltip">
            Delete
          </UncontrolledTooltip>
        </Link>
      ),
    },
  ]
  // tables columns end

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  // table helpers
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "uidNumver",
      order: "desc",
    },
  ]

  // tables helpers end
  //set user handler
  function setUserHandler(user) {
    setSelectedUser(user)
    openUserModalHandler()
  }

  //openCommentModalHandler
  function openUserModalHandler() {
    setOpenUserModal(!openUserModal)
  }

  // handle delete user
  const onClickDelete = uidNumber => {
    setUidNumber(uidNumber)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    dispatch(deleteUser(uidNumber))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  // useEffects

  useEffect(() => {
    dispatch(getUsers())
  }, [])

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      {!loading ? (
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={EcommerceOrderColumns()}
                  data={users}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={users}
                      columns={EcommerceOrderColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  ref={node}
                                />
                              </div>
                              {!users.length > 0 && (
                                <h5 className="mt-2 text-muted text-center">
                                  No Users Found!
                                </h5>
                              )}
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                              <PaginationListStandalone {...paginationProps} />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
      <UserDetailsModal
        openUserModal={openUserModal}
        openUserModalHandler={openUserModalHandler}
        user={selectedUser}
      />
    </React.Fragment>
  )
}

export default Users
